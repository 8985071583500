import {
  STARTFETCHINGUSERDB,
  FETCHEDUSERDB,
  FAILEDFETCHUSERDB,
} from '../types/userDB';

const initState = {
  UserDB: [],
  isFetching: false,
  error: '',
};

export const userDB = (state = initState, action) => {
  switch (action.type) {
    case STARTFETCHINGUSERDB:
      return {
        ...state,
        isFetching: true,
      };
    case FETCHEDUSERDB:
      return {
        ...state,
        isFetching: false,
        UserDB: action.payload,
      };
    case FAILEDFETCHUSERDB:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
