import {
  SET_SHOW_SCAN_FORM_INPUTS,
  SET_FORM_SCAN_LOADING,
  SET_BUSINESS_RESULTS_LOADING,
} from '../types/ui';

const initState = {
  showScanFormInputs: false,
  isScanFormLoading: false,
  areBusinessResultsLoading: true,
};

export const ui = (state = initState, action) => {
  switch (action.type) {
    case SET_SHOW_SCAN_FORM_INPUTS:
      return {
        ...state,
        showScanFormInputs: action.payload,
      };
    case SET_FORM_SCAN_LOADING:
      return {
        ...state,
        isScanFormLoading: action.payload,
      };
    case SET_BUSINESS_RESULTS_LOADING:
      return {
        ...state,
        areBusinessResultsLoading: action.payload,
      };
    default:
      return state;
  }
};
