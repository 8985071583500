import { STARTFETCHING_OAUTH, FETCHED_OAUTH, FAILDFETCH_OAUTH } from '../types/oAuth';

export const startFetchingOAuth = () => ({
  type: STARTFETCHING_OAUTH,
});

export const fetchedOAuth = (data) => ({
  type: FETCHED_OAUTH,
  payload: {
    ...data,
  },
});

export const faildFetchOAuth = (error) => ({
  type: FAILDFETCH_OAUTH,
  payload: {
    error,
  },
});
