import {
  TOGGLE_SHOW_SCAN_FORM_INPUTS,
  SET_SHOW_SCAN_FORM_INPUTS,
  SET_FORM_SCAN_LOADING,
  SET_BUSINESS_RESULTS_LOADING,
} from '../types/ui';

export const toggleShowScanFormInputs = ({ hasError = false }) => ({
  type: TOGGLE_SHOW_SCAN_FORM_INPUTS,
  meta: { hasError },
});

export const setShowScanFormInputs = ({ show }) => ({
  type: SET_SHOW_SCAN_FORM_INPUTS,
  payload: show,
});

export const setFormScanLoading = ({ isLoading }) => ({
  type: SET_FORM_SCAN_LOADING,
  payload: isLoading,
});
export const setBusinessResultsLoading = ({ isLoading }) => ({
  type: SET_BUSINESS_RESULTS_LOADING,
  payload: isLoading,
});
