import { createGlobalStyle } from 'styled-components';

import { SCORE_CLASS_NAMES, SCORE_COLORS } from '../../utils/constants';

export const GlobalStyles = createGlobalStyle`

html,body {
    margin: 0;
    background-color: #fff;
    box-sizing:border-box;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact !important;
  }

 .${SCORE_CLASS_NAMES[0]}{
   color:${SCORE_COLORS[0]};
 } 
 .${SCORE_CLASS_NAMES[1]}{
   color:${SCORE_COLORS[1]};
 } 
 .${SCORE_CLASS_NAMES[2]}{
   color:${SCORE_COLORS[2]};
 } 
 .dot{
   width:10px;
   height:8px;
   border-radius:50%;
   margin:0px 5px;
 }
 .positive__review--dot {
   background-color:#16A085;
}
.negative__review--dot {
  background-color:#DF2A2A;
 }
 .break-page--print-style{
  @media print {
    height: 100vh;
    max-height: 100vh;
    page-break-before: always;
  }
 }
`;

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
