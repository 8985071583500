import { createStore, applyMiddleware, compose } from 'redux';
//import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import RootReducer from './reducers/rootReducer';

import { oAuthMdl } from './middlewares/oAuth';
import { oAuthZeroMdl } from './middlewares/oAuthZero';
import { userDBMdl } from './middlewares/userDB';
import { scanFormMdl } from './middlewares/scanForm';
import { uiMdl } from './middlewares/ui';
import { businessResultsMdl } from './middlewares/businessResults';

//const logger = createLogger({});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  RootReducer,
  composeEnhancers(
    applyMiddleware(
      ...oAuthMdl,
      ...oAuthZeroMdl,
      ...userDBMdl,
      ...uiMdl,
      ...scanFormMdl,
      ...businessResultsMdl,
      thunk
    )
  )
);
