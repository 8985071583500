import { TRANSLATIONS, LANGUAGES } from '../../utils/translations';
import { SET_TRANSLATIONS, SET_LANGUAGE } from '../types/translations';

const initState = {
  language: LANGUAGES[0],
  translations: TRANSLATIONS[LANGUAGES[0]],
};

export const translations = (state = initState, action) => {
  switch (action.type) {
    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
