import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoaderSpinner from '../components/ui/LoaderSpinner';
import { ROUTE_NAMES } from '../utils/constants';

const BusinessScan = lazy(() => import('../pages/BusinessScan'));
const BusinessResult = lazy(() => import('../pages/BusinessResult'));
const Tools = lazy(() => import('../pages/Tools'));
const CountrySelection = lazy(() => import('../pages/CountrySelection'));

const Routes = () => (
  <Suspense fallback={<LoaderSpinner />}>
    <Switch>
      <Route exact path='/' component={() => <div />} />
      <Route exact path={`/${ROUTE_NAMES[0]}`} component={BusinessResult} />
      <Route exact path={`/${ROUTE_NAMES[1]}`} component={BusinessScan} />
      <Route exact path={`/${ROUTE_NAMES[2]}`} component={Tools} />
      <Route exact path={`/${ROUTE_NAMES[3]}`} component={CountrySelection} />
      <Route path='*' component={BusinessScan} />
    </Switch>
  </Suspense>
);

export default Routes;
