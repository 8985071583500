const SCAN_FORM = '[Scan form]';
export const SUBMIT_SCAN_FORM = `${SCAN_FORM} SUBMIT`;
export const SUBMIT_SCAN_FORM_SUCCESS = `${SCAN_FORM} SUBMIT scan form SUCCESS ✔`;
export const SUBMIT_SCAN_FORM_ERROR = `${SCAN_FORM} SUBMIT scan form ERROR ❌`;
export const FETCH_COMPANY_NAME = `${SCAN_FORM} FETCH company name`;
export const FETCH_COMPANY_NAME_SUCCESS = `${SCAN_FORM} FETCH company name SUCCESS ✔`;
export const FETCH_COMPANY_NAME_ERROR = `${SCAN_FORM} FETCH company name ERROR ❌`;
export const SET_PREDICTIONS_RESULTS = `${SCAN_FORM} SET prediction results`;
export const FETCH_COMPANY_INFO = `${SCAN_FORM} FETCH company info`;
export const FETCH_COMPANY_INFO_BY_ID = `${SCAN_FORM} FETCH company info by ID`;
export const FETCH_COMPANY_INFO_SUCCESS = `${SCAN_FORM} FETCH company info SUCCESS ✔`;
export const FETCH_COMPANY_INFO_ERROR = `${SCAN_FORM} FETCH company info ERROR ❌`;
export const PROCESS_COMPANY_INFO = `${SCAN_FORM} PROCESS company info`;
export const SET_COMPANY_INFO = `${SCAN_FORM} SET company info`;
export const RESET_COMPANY_INFO = `${SCAN_FORM} RESET company info`;
export const SET_URL_PARAMETERS = `${SCAN_FORM} SET url parameters`;
