import { SET_LANGUAGE, SET_TRANSLATIONS } from '../types/translations';

export const setLanguage = ({ language = false }) => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const setTranslations = ({ translations }) => ({
  type: SET_TRANSLATIONS,
  payload: translations,
});
