import { STARTFETCHING_OAUTH, FETCHED_OAUTH, FAILDFETCH_OAUTH } from '../types/oAuth';

const initState = {
  oauth: [],
  isFetching: false,
  error: '',
};

export const oAuth = (state = initState, action) => {
  switch (action.type) {
    case STARTFETCHING_OAUTH:
      return {
        ...state,
        isFetching: true,
      };
    case FETCHED_OAUTH:
      return {
        ...state,
        isFetching: false,
        oauth: action.payload,
      };
    case FAILDFETCH_OAUTH:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
