import {
  SUBMIT_SCAN_FORM,
  FETCH_COMPANY_NAME,
  SET_PREDICTIONS_RESULTS,
  FETCH_COMPANY_INFO,
  FETCH_COMPANY_INFO_BY_ID,
  SET_COMPANY_INFO,
  RESET_COMPANY_INFO,
  PROCESS_COMPANY_INFO,
  SET_URL_PARAMETERS,
} from '../types/scanForm';

export const fetchCompanyName = ({ query }) => ({
  type: FETCH_COMPANY_NAME,
  payload: query,
});

export const setPredictionsResults = ({ predictions }) => ({
  type: SET_PREDICTIONS_RESULTS,
  payload: predictions,
});

export const fetchCompanyInfo = ({ companyName }) => ({
  type: FETCH_COMPANY_INFO,
  payload: companyName,
});

export const fetchCompanyInfoById = ({ googleId }) => ({
  type: FETCH_COMPANY_INFO_BY_ID,
  payload: googleId,
});

export const setCompanyInfo = ({ companyInfo }) => ({
  type: SET_COMPANY_INFO,
  payload: companyInfo,
});
export const processCompanyInfo = ({ companyInfo }) => ({
  type: PROCESS_COMPANY_INFO,
  payload: companyInfo,
});

export const resetCompanyInfo = () => ({
  type: RESET_COMPANY_INFO,
});

export const setUrlParameters = ({
  recordId,
  country,
  networkId,
  pathName,
}) => ({
  type: SET_URL_PARAMETERS,
  payload: { recordId, country, networkId },
  meta: { pathName },
});

export const submitScanForm = () => ({
  type: SUBMIT_SCAN_FORM,
});
