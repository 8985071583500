import {
  STARTFETCHINGUSERDB,
  FETCHEDUSERDB,
  FAILEDFETCHUSERDB,
} from '../types/userDB';

export const startFetchingUserDB = () => ({
  type: STARTFETCHINGUSERDB,
});

export const fetchedUserDB = (data) => ({
  type: FETCHEDUSERDB,
  payload: {
    ...data,
  },
});

export const failedFetchUserDB = (error) => ({
  type: FAILEDFETCHUSERDB,
  payload: {
    error,
  },
});
