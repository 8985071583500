export const SCORE_TEXTS = [
  'Excellent!',
  'You are almost there!',
  'You can do better!',
  'Ups, something wrong happen!',
];

export const SCORE_COLORS = ['#16A085', '#FFB800', '#DF2A2A', '#057AFF'];

export const SCORE_CLASS_NAMES = [
  'score-primary',
  'score-warning',
  'score-error',
];

export const WEBSITE_ANALYSIS_ICON_CONFIG = [
  { icon: 'circleCheck', color: SCORE_COLORS[0] },
  { icon: 'infoCircle', color: SCORE_COLORS[3] },
  { icon: 'circleX', color: SCORE_COLORS[2] },
];

export const LISTING_ANALYSIS_TEST_DATA = [
  {
    id: 1,
    site: 'Yelp',
    businessName: 'APPLE',
    address: '',
    phone: '455.345.2341',
    reviews: true,
  },
  {
    id: 2,
    site: 'Bing',
    businessName: 'APPLE',
    address: '2345 Belmont Avem Chicago, IL 60934',
    phone: '',
    reviews: true,
  },
  {
    id: 3,
    site: 'YP.com',
    businessName: '',
    address: '2345 Belmont Avem Chicago, IL 60934',
    phone: '455.345.2341',
    reviews: true,
  },
  {
    id: 4,
    site: 'Facebook',
    businessName: 'APPLE',
    address: '2345 Belmont Avem Chicago, IL 60934',
    phone: '455.345.2341',
    reviews: false,
  },
  {
    id: 5,
    site: 'Google',
    businessName: 'APPLE',
    address: '2345 Belmont Avem Chicago, IL 60934',
    phone: '455.345.2341',
    reviews: true,
  },
  {
    id: 6,
    site: 'Yahoo',
    businessName: 'APPLE',
    address: '2345 Belmont Avem Chicago, IL 60934',
    phone: '455.345.2341',
    reviews: false,
  },
];

export const REDIRECT_URL = {
  ausUrl: 'https://sellthryvmustg.wpengine.com/aus',
  defaultUrl: 'https://sellthryvmustg.wpengine.com/usa',
};

export const SCHEDULE_DEMO_URL = {
  ausUrl: 'https://sellthryvmustg.wpengine.com/aus/demo-request/',
  defaultUrl: 'https://sellthryvmustg.wpengine.com/usa/demo-request/',
};

export const ROUTE_NAMES = ['scan-result', 'scan-form', 'tools', 'country-selection'];
