import axios from 'axios';
import { FETCHED_OAUTHZERO } from '../types/oAuthZero';
import { startFetchingOAuthZero, fetchedOAuthZero } from '../actions/oAuthZero';

export const oAuthZero =
  ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === FETCHED_OAUTHZERO) {
      const oauthData = '123';
      dispatch(startFetchingOAuthZero());
      // const token = '..your token..'
      const data =
        '750310BA3172421E92B9DB6984D35E67:JD1aryJQNkHkoPV-8dD1c1G2n4rdyJWsUDWGJe8My9K93ENx';
      // eslint-disable-next-line no-buffer-constructor
      const buff1 = new Buffer(data);
      const stringToBase64 = buff1.toString('base64');
      const token = stringToBase64;
      const authStr = `Basic ${token}`;
      axios.defaults.headers.post['Content-Type'] =
        'application/x-www-form-urlencoded';
      axios
        .post(
          'https://appsbackend-staging.thryv.com/api/xero/thryvUser/token',
          {
            payload: oauthData,
            token: authStr,
          },
        )
        .then((response) => {
          console.log('success in token response');
          //console.log(response);
          const responseAndDecodedData = {
            response: response.data,
          };

          localStorage.setItem('xero_access_token', response.data.access_token);
          localStorage.setItem('xero_expires_in', response.data.expires_in);

          localStorage.setItem('xero_id_token', response.data.id_token);
          localStorage.setItem(
            'xero_refresh_token',
            response.data.refresh_token,
          );

          localStorage.setItem('xero_scope', response.data.scope);
          localStorage.setItem('xero_token_type', response.data.token_type);

          //responseAndDecodedData="swe"
          // store response.data along with the decoded data to access business_id, and email in Redux Store
          dispatch(fetchedOAuthZero(responseAndDecodedData));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

export const oAuthZeroMdl = [oAuthZero];
