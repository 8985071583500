export const getOutReachScore = ({
  facebookPage,
  twitterLink,
  hasInstagram,
}) => {
  let result = 0;
  if (!facebookPage && twitterLink && hasInstagram) {
    result = 66;
  }
  if (hasInstagram === false && facebookPage && twitterLink) {
    result = 66;
  }
  if (!twitterLink && facebookPage && hasInstagram) {
    result = 66;
  }
  if (!facebookPage && !twitterLink && hasInstagram) {
    result = 33;
  }
  if (!facebookPage && !hasInstagram && twitterLink) {
    result = 33;
  }
  if (!twitterLink && !hasInstagram && facebookPage) {
    result = 33;
  }
  if (!facebookPage && !twitterLink && hasInstagram) {
    result = 0;
  }
  if (twitterLink && facebookPage && hasInstagram) {
    result = 100;
  }
  return result;
};
export const getPresenceScore = ({ presence }) => {
  return Math.round(presence / 2);
};

export const getAverageScore = ({
  reputationScore,
  presenceScore,
  outReachScore,
}) => {
  const total = outReachScore + presenceScore + reputationScore;
  return Math.ceil(total / 3) || 0;
};
