import { SET_BUSINESS_RESULTS } from '../types/businessResults';

const initState = {};

export const businessResults = (state = initState, action) => {
  switch (action.type) {
    case SET_BUSINESS_RESULTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
