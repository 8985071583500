import axios from 'axios';
import { FETCHED_OAUTH } from '../types/oAuth';
import {
  startFetchingOAuth,
  fetchedOAuth,
  faildFetchOAuth,
} from '../actions/oAuth';

export const oAuth = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === FETCHED_OAUTH) {
    dispatch(startFetchingOAuth());
    console.log();
    const { token: oauthData } = action.payload;
    console.log('parm from ThryvPay to OAuthActions: ', oauthData);
    axios
      .post('https://api.thryv.com/oauth/token', oauthData)
      .then((response) => {
        console.log({ response });
        const idTokenResponse = response.data.id_token;
        const idToken = idTokenResponse.split('.');
        // remove index of 0 and 2 within idToken array to isolate the string we want
        idToken.splice(0, 1);
        idToken.splice(1, 1);
        // decode ID token with base64
        const base64 = window.atob(idToken);
        console.log('base64: ', base64);
        // localStorage.setItem("app_name", storeThirdPartyLink)
        // parse the decoded token to get business_id and email
        const decodedData = JSON.parse(base64);
        console.log('decodedData: ', decodedData);

        const responseAndDecodedData = {
          response: response.data,
          usersData: decodedData,
        };
        localStorage.setItem('business_id', decodedData.business_id);
        localStorage.setItem('access_token', response.data.access_token);

        localStorage.setItem('business_name', decodedData.business_name);
        console.log('items set in localstorage');
        console.log(localStorage.getItem('business_id'));
        // store response.data along with the decoded data to access business_id, and email in Redux Store
        dispatch(fetchedOAuth(responseAndDecodedData));
      })
      .catch((error) => {
        dispatch(faildFetchOAuth(error));
      });
  }
};

export const oAuthMdl = [oAuth];
