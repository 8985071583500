/* eslint-disable camelcase */
import axios from 'axios';
import { FETCHEDUSERDB } from '../types/userDB';
import {
  startFetchingUserDB,
  fetchedUserDB,
  failedFetchUserDB,
} from '../actions/userDB';

export const userDB =
  ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === FETCHEDUSERDB) {
      dispatch(startFetchingUserDB());
      console.log('blahblah: ', thryv_id);
      axios
        .get(`https://appsbackend-staging.thryv.com/api/thryvUser/${thryv_id}`)
        .then((response) => {
          console.log(response);

          const responseFromDB = {
            response,
          };

          // store response.data along with the decoded data to access business_id, and email in Redux Store
          dispatch(fetchedUserDB(responseFromDB));
        })
        .catch((error) => {
          dispatch(failedFetchUserDB(error));
        });
    }
  };

export const userDBMdl = [userDB];
