import React from 'react';

import { MainContainer } from './styles';

const LoaderSpinner = () => (
  <MainContainer>
    <div className='lds-roller'>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </MainContainer>
);

export default LoaderSpinner;
