export const LANGUAGES = ['en_US', 'en_AUS'];

export const TRANSLATIONS = {
  en_US: {
    reputationTooltips: {
      reviews:
        'We’ve pulled some key statements from popular review sites like Google, Yelp and Yellow. Below you will see some of the good, the bad, and the ugly. If you have bad reviews, don’t fret, everyone does. No one is perfect, and your customers don’t expect you to be either. Pro Tip: Responding to your reviews shows future prospects that you care, are on top of things, and take customer service seriously. 53% of consumers expect businesses to respond to reviews, but 63% of those survey responded that none of their reviews have ever been responded to.1 Stand out from your competition and routinely respond to both positive and negative reviews online.        https://www.reviewtrackers.com/online-reviews-survey/',
      sentimentAnalysis:
        'We’ve pulled some key statements from popular review sites like Google, Yelp and Yellow. Below you will see some of the good, the bad, and the ugly. If you have bad reviews, don’t fret, everyone does. Pro Tip: Responding to your reviews shows future prospects that you care, are on top of things, and take customer service seriously. 53% of consumers expect businesses to respond to reviews, but 63% of those survey responded that none of their reviews have ever been responded to. 1 Stand out from your competition and routinely respond to both positive and negative reviews online. https://www.reviewtrackers.com/online-reviews-survey/',
      revenueImpact: '',
    },
    reviewTooltips: {
      amountOfReviews:
        'Consumers want to read, on average, 10 reviews before feeling able to trust a business.1 Additionally, 49% of consumers responded that quantity of reviews is what they pay attention to. Second only to overall rating at 56%. The quantity of reviews is important for any business online. Not only does it build trust with consumers, but it also can protect your business from wide swings in overall performance. 1. https://www.brightlocal.com/research/local-consumer-review-survey/',
      sentimentScore:
        'While your overall review score is very important, it is also important to read what your customers have written about your business. Your overall sentiment score analyzes individual phrases to identify how positive, or negative your customers have spoken about your business online.',
      recentReviewActivity:
        '40% of consumers only take into account reviews written with the past 2 weeks – up from 18% last year.1 Consumers want to see a steady stream of reviews. This shows continued interest in your business, and that your recent work is thought highly of. If the last review you have is from a few months ago, it makes consumers start to question your business. 1. https://www.brightlocal.com/research/local-consumer-review-survey/',
      averageReviewScore:
        'In 2018, over 56% of consumers survey responded that your review score is the first thing they see.1 Take control of your review score. Investing to build up a large base of high quality reviews can protect your business long term from the occasional bad review. A business with 1,000 online reviews fluctuates far less then a business with 100. 1. https://www.brightlocal.com/research/local-consumer-review-survey/',
    },
    revenueImpactTooltip: '',
    presenceDescription:
      'Enhance your online presence. Offer customers an interactive login area online, sync your business info across 40+ listings sites, and get a fast, modern, mobile-ready website',
    presenceThryvTip:
      'Upload your business information once, and watch it post to 40+ trusted listings sites online. Thryv locks this information down, giving consumers and search engines confidence in you. Calls to action help consumers engage with you even further.',
    presenceTooltips: {
      website:
        'Promote your brand online with a custom website, and use compelling calls to action to get customers to book more and buy more. Thryv’s websites are also optimized to make you as visible as possible with top search engines.',
      listingAnalysis:
        'Having incorrect information on consumer listings sites can drive your engagement down. We want to help you get this corrected fast!',
    },
    outreachTooltips: {
      facebook:
        'A recent Pew Research Center study found that 68% of American adults are Facebook users. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018/',
      twitter:
        'Among 18 to 24-year-olds, 45% are on Twitter. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018',
      instagram:
        'Among 18 to 24-year-olds, 78% use Instagram. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018',
    },
    websiteAnalysis: {
      website: {
        alternativeText:
          'Alternative text or Alt Text is a word or phrase that tells website viewers the nature or contents of an image. Alt text should include relevant keywords which can improve your website’s search engine optimization (SEO).',
        analytics:
          'Analyzes the behavior of visitors to a website, including page views, clicks, bounce rate, average session duration, and more.',
        brokenLinks: `These are links on a web page that lead to a non-existent page, websites or image. When a broken link is clicked, an error message is displayed, which create bad impressions and unprofessional images.  
        Images (optimized/not optimized):
        Images on a website should be high-quality images in the right format, dimension, size, and resolution while keeping the smallest possible size. If an image is not optimized it could appear incorrectly or too large, which impacts the end user experience and website performance.
        `,
        ssl:
          'SSL (Secure Sockets Layer) is the standard security technology for establishing an encrypted link between a web server and a browser. Detects whether the SSL certificate on the web server is correctly installed, valid, trusted, and does not give any errors to users.',
        titlesAndDescriptions:
          'A website title, or title tag, is an HTML element that specifies the content of a webpage. The title and description are crucial to setting your website apart in search results. Including same title and description on every page creates duplicate content which search engines may penalize.',
        mobileSpeed:
          'The Site Speed reports show how quickly users can see and interact with content. 47% of consumers expect a web page to load in 2 seconds or less. A mobile speed under 4 seconds is a good speed.',
      },
      responsive: {
        tabletFriendly:
          'Means your website’s information, including images, texts, videos, links are viewable and site is easy to navigate in tablet format.',
        mobileFriendly:
          'Means your website’s information, including images, texts, videos, links are viewable and site is easy to navigate in mobile format.',
      },
      paidSearch: {
        paidSearch: 'Detects any found paid search associated to the website.',
        paidKeywords:
          'Detects and displays any found paid keywords associated to the website.',
        displayAds:
          'Detects any found display ads or programs associated to the website.',
      },
      organicSearch: {
        organicReferrals:
          'Detects any organic traffic and estimates the number of organic referrals to the website within the last month. Organic Referrals or Organic Traffic includes any visitors that arrive from search engines.',
        searchTerms:
          'Identifies any organic search terms/keywords associated to the website.',
        amountOfContent:
          'The amount of content needed on a website can vary depending on the industry, audience, and purpose of the website. Content should include most relevant and important information above the fold, with clear calls to action, short bullets and sentences, images and videos. Informative content can help improve the end user experience and length of visitors site duration.',
        backlinks:
          'Also known as "inbound links" or "incoming links," are created when one website links to another. Backlinks to your website are a signal to search engines giving credibility for your content, which may have a positive effect on a website ranking position or search visibility.',
        domainAge:
          'Refers to length of time that a website has been registered and active. Domain a(A)ge conveys trust to website visitors and search engines.',
        lastUpdate:
          'Identifies the last time the site was updated. Keeping a website updated with fresh content is important, helping with search engine organic traffic, website security and visitor’s user experience.',
        websiteSize:
          'Website size refers to the number of pages that make up a website. Finding a balance between enough content and pages is important, ensuring consumers are finding the right information, as well as search engines and site ranking.',
      },
      local: {
        googleMyBusiness:
          'Detects if a claimed Google My Business listing is associated to the website.',
        googleMaps:
          'Detects if a claimed Google Maps listing is associated to the website.',
        yelp: 'Detects if a claimed Yelp listing is associated to the website.',
        foursquare:
          'Detects if a claimed Foursquare listing is associated to the website.',
      },
    },
  },
  en_AUS: {
    reputationTooltips: {
      reviews:
        'We’ve pulled some key statements from popular review sites like Google, Yelp and Yellow. Below you will see some of the good, the bad, and the ugly. If you have bad reviews, don’t fret, everyone does. No one is perfect, and your customers don’t expect you to be either. Pro Tip: Responding to your reviews shows future prospects that you care, are on top of things, and take customer service seriously. 53% of consumers expect businesses to respond to reviews, but 63% of those survey responded that none of their reviews have ever been responded to.1 Stand out from your competition and routinely respond to both positive and negative reviews online.        https://www.reviewtrackers.com/online-reviews-survey/',
      sentimentAnalysis:
        'We’ve pulled some key statements from popular review sites like Google, Yelp and Yellow. Below you will see some of the good, the bad, and the ugly. If you have bad reviews, don’t fret, everyone does. Pro Tip: Responding to your reviews shows future prospects that you care, are on top of things, and take customer service seriously. 53% of consumers expect businesses to respond to reviews, but 63% of those survey responded that none of their reviews have ever been responded to. 1 Stand out from your competition and routinely respond to both positive and negative reviews online. https://www.reviewtrackers.com/online-reviews-survey/',
      revenueImpact: '',
    },
    reviewTooltips: {
      amountOfReviews:
        'Consumers want to read, on average, 10 reviews before feeling able to trust a business.1 Additionally, 49% of consumers responded that quantity of reviews is what they pay attention to. Second only to overall rating at 56%. The quantity of reviews is important for any business online. Not only does it build trust with consumers, but it also can protect your business from wide swings in overall performance. 1. https://www.brightlocal.com/research/local-consumer-review-survey/',
      sentimentScore:
        'While your overall review score is very important, it is also important to read what your customers have written about your business. Your overall sentiment score analyzes individual phrases to identify how positive, or negative your customers have spoken about your business online.',
      recentReviewActivity:
        '40% of consumers only take into account reviews written with the past 2 weeks – up from 18% last year.1 Consumers want to see a steady stream of reviews. This shows continued interest in your business, and that your recent work is thought highly of. If the last review you have is from a few months ago, it makes consumers start to question your business. 1. https://www.brightlocal.com/research/local-consumer-review-survey/',
      averageReviewScore:
        'In 2018, over 56% of consumers survey responded that your review score is the first thing they see.1 Take control of your review score. Investing to build up a large base of high quality reviews can protect your business long term from the occasional bad review. A business with 1,000 online reviews fluctuates far less then a business with 100. 1. https://www.brightlocal.com/research/local-consumer-review-survey/',
    },
    revenueImpactTooltip: '',
    presenceDescription:
      'Enhance your online presence. Offer customers an interactive login area online, sync your business info across 40+ listings sites, and get a fast, modern, mobile-ready website',
    presenceThryvTip:
      'Upload your business information once, and watch it post to 40+ trusted listings sites online. Thryv locks this information down, giving consumers and search engines confidence in you. Calls to action help consumers engage with you even further.',
    presenceTooltips: {
      website:
        'Promote your brand online with a custom website, and use compelling calls to action to get customers to book more and buy more. Thryv’s websites are also optimised to make you as visible as possible with top search engines.',
      listingAnalysis:
        'Having incorrect information on consumer listings sites can drive your engagement down. We want to help you get this corrected fast!',
    },
    outreachTooltips: {
      facebook:
        'A recent Pew Research Center study found that 68% of American adults are Facebook users. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018/',
      twitter:
        'Among 18 to 24-year-olds, 45% are on Twitter. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018',
      instagram:
        'Among 18 to 24-year-olds, 78% use Instagram. - Pew Research https://www.pewresearch.org/internet/2018/03/01/social-media-use-in-2018',
    },
    websiteAnalysis: {
      website: {
        alternativeText:
          'Alternative text or Alt Text is a word or phrase that tells website viewers the nature or contents of an image. Alt text should include relevant keywords which can improve your website’s search engine optimisation (SEO).',
        analytics:
          'Analyses the behavior of visitors to a website, including page views, clicks, bounce rate, average session duration, and more.',
        brokenLinks: `These are links on a web page that lead to a non-existent page, websites or image. When a broken link is clicked, an error message is displayed, which create bad impressions and unprofessional images.  
      Images (optimised/not optimised):
      Images on a website should be high-quality images in the right format, dimension, size, and resolution while keeping the smallest possible size. If an image is not optimised it could appear incorrectly or too large, which impacts the end user experience and website performance.
      `,
        ssl:
          'SSL (Secure Sockets Layer) is the standard security technology for establishing an encrypted link between a web server and a browser. Detects whether the SSL certificate on the web server is correctly installed, valid, trusted, and does not give any errors to users.',
        titlesAndDescriptions:
          'A website title, or title tag, is an HTML element that specifies the content of a webpage. The title and description are crucial to setting your website apart in search results. Including same title and description on every page creates duplicate content which search engines may penalise.',
        mobileSpeed:
          'The Site Speed reports show how quickly users can see and interact with content. 47% of consumers expect a web page to load in 2 seconds or less. A mobile speed under 4 seconds is a good speed.',
      },
      responsive: {
        tabletFriendly:
          'Means your website’s information, including images, texts, videos, links are viewable and site is easy to navigate in tablet format.',
        mobileFriendly:
          'Means your website’s information, including images, texts, videos, links are viewable and site is easy to navigate in mobile format.',
      },
      paidSearch: {
        paidSearch: 'Detects any found paid search associated to the website.',
        paidKeywords:
          'Detects and displays any found paid keywords associated to the website.',
        displayAds:
          'Detects any found display ads or programs associated to the website.',
      },
      organicSearch: {
        organicReferrals:
          'Detects any organic traffic and estimates the number of organic referrals to the website within the last month. Organic Referrals or Organic Traffic includes any visitors that arrive from search engines.',
        searchTerms:
          'Identifies any organic search terms/keywords associated to the website.',
        amountOfContent:
          'The amount of content needed on a website can vary depending on the industry, audience, and purpose of the website. Content should include most relevant and important information above the fold, with clear calls to action, short bullets and sentences, images and videos. Informative content can help improve the end user experience and length of visitors site duration.',
        backlinks:
          'Also known as "inbound links" or "incoming links," are created when one website links to another. Backlinks to your website are a signal to search engines giving credibility for your content, which may have a positive effect on a website ranking position or search visibility.',
        domainAge:
          'Refers to length of time that a website has been registered and active. Domain a(A)ge conveys trust to website visitors and search engines.',
        lastUpdate:
          'Identifies the last time the site was updated. Keeping a website updated with fresh content is important, helping with search engine organic traffic, website security and visitor’s user experience.',
        websiteSize:
          'Website size refers to the number of pages that make up a website. Finding a balance between enough content and pages is important, ensuring consumers are finding the right information, as well as search engines and site ranking.',
      },
      local: {
        googleMyBusiness:
          'Detects if a claimed Google My Business listing is associated to the website.',
        googleMaps:
          'Detects if a claimed Google Maps listing is associated to the website.',
        yelp: 'Detects if a claimed Yelp listing is associated to the website.',
        foursquare:
          'Detects if a claimed Foursquare listing is associated to the website.',
      },
    },
  },
};
