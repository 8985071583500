import {
  FETCH_BUSINESS_RESULTS,
  SET_BUSINESS_RESULTS,
} from '../types/businessResults';

export const fetchBusinessResults = ({ companyId }) => ({
  type: FETCH_BUSINESS_RESULTS,
  payload: companyId,
});

export const setBusinessResults = ({ businessresults }) => ({
  type: SET_BUSINESS_RESULTS,
  payload: businessresults,
});
