import { TOGGLE_SHOW_SCAN_FORM_INPUTS } from '../types/ui';
import { setShowScanFormInputs } from '../actions/ui';

export const toggleShowScanFormInputs =
  ({ dispatch, getState }) => (next) => (action) => {
    next(action);
    if (action.type === TOGGLE_SHOW_SCAN_FORM_INPUTS) {
      const { hasError } = action.meta;
      if (hasError) {
        dispatch(
          setShowScanFormInputs({ show: true }),
        );
      } else {
        const {
          ui: {
            showScanFormInputs,
          },
        } = getState();
        dispatch(
          setShowScanFormInputs({ show: !showScanFormInputs }),
        );
      }
    }
  };

export const uiMdl = [
  toggleShowScanFormInputs,
];
