import { createSelector } from 'reselect';
import {
  SET_PREDICTIONS_RESULTS,
  FETCH_COMPANY_NAME,
  SET_COMPANY_INFO,
  RESET_COMPANY_INFO,
} from '../types/scanForm';

const initState = {
  recordId: 0,
  formInfo: {
    businessDisplayName: '',
    businessName: '',
    businessAddress: '',
    suiteNo: '',
    city: '',
    state: '',
    zip: '',
    businessPhoneNumber: '',
    businessWebsiteURL: '',
    annualBusinessRevenue: '',
    country: null,
    networkId: null,
  },
  predictions: [],
  isFetchingCompanies: false,
  isSubmiting: false,
  error: '',
};

export const scanForm = (state = initState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_NAME:
      return {
        ...state,
        isFetchingCompanies: true,
      };
    case SET_COMPANY_INFO:
      return {
        ...state,
        formInfo: { ...state.formInfo, ...action.payload },
      };
    case RESET_COMPANY_INFO:
      return {
        ...state,
        formInfo: initState.formInfo,
      };
    case SET_PREDICTIONS_RESULTS:
      return {
        ...state,
        isFetchingCompanies: false,
        predictions: action.payload,
      };
    default:
      return state;
  }
};

export const selectPredictionsDescriptions = createSelector(
  (state) => state.predictions,
  (predictions) => predictions.map(({ description }) => description)
);
