import { STARTFETCHING_OAUTHZERO, FETCHED_OAUTHZERO, FAILDFETCH_OAUTHZERO } from '../types/oAuthZero';

export const startFetchingOAuthZero = () => ({
  type: STARTFETCHING_OAUTHZERO,
});

export const fetchedOAuthZero = (data) => ({
  type: FETCHED_OAUTHZERO,
  payload: {
    ...data,
  },
});

export const faildFetchOAuthZero = (error) => ({
  type: FAILDFETCH_OAUTHZERO,
  payload: {
    error,
  },
});
