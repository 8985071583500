import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
// imported components:
import Routes from '../routes';
import { GlobalStyles } from '../assets/styles/GlobalStyles';

import { setUrlParameters } from '../redux/actions/scanForm';

import '../assets/styles/App.css';

const App = (props) => {
  const { setUrlParameters } = props;
  const { search, pathname } = useLocation();
  const pendoInitialize = ({ networkId }) => {
    if (!window.pendo) return;
    pendo.initialize({
      apiKey: 'c85ec91d-c561-4f73-45ad-a20f250e9ee3',
      visitor: {
        id: networkId,
      },

      account: {
        id: networkId,
      },
    });
  };

  useEffect(() => {
    const params = search.replace('?', '');
    const query = new URLSearchParams(params);
    const networkId = query.get('rep_id');
    const country = query.get('ctry');
    const recordId = query.get('record_id');
    setUrlParameters({ recordId, country, networkId, pathName: pathname });
    if (networkId) {
      pendoInitialize({ networkId });
    }
  }, [search, setUrlParameters, pathname]);
  return (
    <>
      <ToastContainer />
      <GlobalStyles />
      <Routes />
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setUrlParameters,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
