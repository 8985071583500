import axios from 'axios';
import { toast } from 'react-toastify';

import {
  FETCH_COMPANY_NAME,
  FETCH_COMPANY_INFO,
  FETCH_COMPANY_INFO_BY_ID,
  PROCESS_COMPANY_INFO,
  SET_URL_PARAMETERS,
  SUBMIT_SCAN_FORM,
  SUBMIT_SCAN_FORM_SUCCESS,
  SUBMIT_SCAN_FORM_ERROR,
} from '../types/scanForm';
import {
  setPredictionsResults,
  setCompanyInfo,
  //resetCompanyInfo,
  processCompanyInfo,
  fetchCompanyInfoById,
} from '../actions/scanForm';
import { setFormScanLoading } from '../actions/ui';
import { ROUTE_NAMES } from '../../utils/constants';
import { LANGUAGES, TRANSLATIONS } from '../../utils/translations';
import { setLanguage, setTranslations } from '../actions/translations';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchCompanyName = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_COMPANY_NAME) {
    //dispatch(resetCompanyInfo());
    const service = new google.maps.places.AutocompleteService();
    service.getQueryPredictions(
      {
        input: action.payload,
      },
      (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          let predictions = [];
          if (result?.length > 0) {
            predictions =
              result &&
              result.map(({ place_id: placeId, description }) => ({
                placeId,
                description,
              }));
          }
          dispatch(
            setPredictionsResults({
              predictions,
            })
          );
        }
      }
    );
  }
};

const getCompanyLocationInfo = ({ googleaddressComponents }) => {
  const result = {};
  googleaddressComponents.forEach((addressComponent) => {
    switch (addressComponent.types[0]) {
      case 'route':
        result['businessAddress'] = addressComponent.long_name;
        break;
      case 'locality':
        result['city'] = addressComponent.long_name;
        break;
      case 'street_number':
        result['suiteNo'] = addressComponent.long_name;
        break;
      case 'administrative_area_level_1':
        result['state'] = addressComponent.long_name;
        break;
      case 'country':
        result['country'] = addressComponent.long_name;
        break;
      case 'postal_code':
        result['zip'] = addressComponent.long_name;
        break;
    }
  });
  return result;
};

const getGooglePlaceDetails = ({ dispatch, placeId, placeDescription }) => {
  const map = new google.maps.Map(document.getElementById('map'), {});
  const service = new google.maps.places.PlacesService(map);
  const detailsRequest = {
    placeId,
    fields: [
      'name',
      'address_components',
      'formatted_phone_number',
      'website',
      'formatted_address',
    ],
  };
  service.getDetails(detailsRequest, (placeInfo, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const companyInfo = {
        businessDisplayName:
          placeDescription || placeInfo.formatted_address || null,
        businessWebsiteURL: placeInfo.website || null,
        businessName: placeInfo.name,
        ...getCompanyLocationInfo({
          googleaddressComponents: placeInfo.address_components,
        }),
      };
      if (placeInfo.formatted_phone_number) {
        companyInfo['businessPhoneNumber'] = placeInfo.formatted_phone_number;
      }
      dispatch(
        processCompanyInfo({
          companyInfo,
        })
      );
      dispatch(setFormScanLoading({ isLoading: false }));
    }
  });
};

export const fetchSelectedCompanyInfo = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);
  if (action.type === FETCH_COMPANY_INFO) {
    const {
      scanForm: { predictions },
    } = getState();
    const { placeId, description } = predictions.find(
      ({ description }) =>
        description.toLowerCase().replace(/ /g, '') ===
        action.payload.toLowerCase().replace(/ /g, '')
    );
    getGooglePlaceDetails({ dispatch, placeId, placeDescription: description });
  }
};

export const fetchSelectedCompanyInfoById = ({ dispatch }) => (next) => (
  action
) => {
  next(action);
  if (action.type === FETCH_COMPANY_INFO_BY_ID) {
    getGooglePlaceDetails({ dispatch, placeId: action.payload });
  }
};

const setPhoneFormat = ({ businessPhoneNumber, country = '' }) => {
  let result = '';
  const ausPhoneCountry = ['australia', 'aus'];
  let formatedPhoneNumber = businessPhoneNumber || '';
  formatedPhoneNumber = formatedPhoneNumber.replace(/[\s().\\-]/g, '');
  if (!ausPhoneCountry.includes((country || '').toLowerCase())) {
    if (formatedPhoneNumber.match(/^(\+)/g)) {
      formatedPhoneNumber = formatedPhoneNumber.replace(/[\\+]/g, '');
      formatedPhoneNumber = formatedPhoneNumber.substring(2);
    }
    formatedPhoneNumber = formatedPhoneNumber.split('');
    for (let i = 0; i < formatedPhoneNumber.length; i++) {
      switch (i) {
        case 0:
          result = `(${formatedPhoneNumber[i]}`;
          break;
        case 1:
          if (country === ausPhoneCountry) {
            result = `${result}${formatedPhoneNumber[i]}) `;
          } else {
            result = `${result}${formatedPhoneNumber[i]}`;
          }
          break;
        case 2:
          if (country === ausPhoneCountry) {
            result = `${result}${formatedPhoneNumber[i]}`;
          } else {
            result = `${result}${formatedPhoneNumber[i]}) `;
          }
          break;
        case 5:
          result = `${result}${formatedPhoneNumber[i]}-`;
          break;
        default:
          result = `${result}${formatedPhoneNumber[i]}`;
          break;
      }
    }
  } else {
    result = formatedPhoneNumber;
  }
  return result;
};

const formatCompanyFormInfo = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);
  if (action.type === PROCESS_COMPANY_INFO) {
    const {
      scanForm: { formInfo },
    } = getState();
    const { businessPhoneNumber, country } = action.payload;
    const countryPhoneFormat = formInfo.country || country;
    const companyInfo = {
      ...action.payload,
    };
    if (businessPhoneNumber) {
      companyInfo['businessPhoneNumber'] = setPhoneFormat({
        businessPhoneNumber,
        country: countryPhoneFormat,
      });
    }

    dispatch(
      setCompanyInfo({
        companyInfo,
        country: formInfo.country || country,
      })
    );
  }
};

const setUrlParameters = ({ dispatch }) => (next) => async (action) => {
  next(action);
  if (action.type === SET_URL_PARAMETERS) {
    const { recordId, country, networkId } = action.payload;
    const { pathName } = action.meta;
    if (recordId && pathName !== `/${ROUTE_NAMES[0]}`) {
      dispatch(setFormScanLoading({ isLoading: true }));
      const result = await axios
        .get(
          `${backendUrl}/companies?recordId=${recordId}&networkId=${networkId}`
        )
        .then((res) => {
          console.log(res.data);
          return res.data.result;
        })
        .catch((error) => {
          let messages = null;
          if (error.response) {
            messages = error.response.data.messages.description;
            toast.error(messages);
          }
        });
      if (result) {
        const companyInfo = {
          businessDisplayName: result.name,
          businessName: result.name,
          businessWebsiteURL: result.businessWebsiteURL,
          businessPhoneNumber: result.phoneNumber,
          annualBusinessRevenue: result.revenue,
          zip: result.zip,
          businessAddress: result.address,
          city: result.city,
          state: result.state,
          country: country || '',
          networkId: networkId || '',
          recordId,
        };
        dispatch(setCompanyInfo({ companyInfo }));
        if (result.googleId) {
          dispatch(fetchCompanyInfoById({ googleId: result.googleId }));
        } else {
          dispatch(setFormScanLoading({ isLoading: false }));
        }
      } else {
        dispatch(setFormScanLoading({ isLoading: false }));
      }
    } else {
      const language = country === 'aus' ? LANGUAGES[1] : LANGUAGES[0];
      const translations =
        country === 'aus' ? TRANSLATIONS.en_AUS : TRANSLATIONS.en_US;
      dispatch(setLanguage({ language }));
      dispatch(setTranslations({ translations }));
      dispatch(
        setCompanyInfo({
          companyInfo: {
            country: country || '',
            networkId: networkId || '',
            recordId,
          },
        })
      );
    }
  }
};
const submitForm = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === SUBMIT_SCAN_FORM) {
    dispatch(setFormScanLoading({ isLoading: true }));
    let {
      scanForm: { formInfo },
    } = getState();
    formInfo = {
      ...formInfo,
      businessName: formInfo.businessName || formInfo.businessDisplayName,
    };
    //toast.info("This proccess could take some time, please don't close or reload the window!", { autoClose: false });
    axios
      .post(`${backendUrl}/companies/`, {
        formInfo,
      })
      .then((res) => {
        dispatch({
          type: SUBMIT_SCAN_FORM_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        let messages = error;
        if (error.response) {
          messages = error.response.data.messages;
        }
        dispatch({
          type: SUBMIT_SCAN_FORM_ERROR,
          payload: messages,
        });
      });
  }
};
const processSubmitFormResponse = ({ dispatch }) => (next) => async (
  action
) => {
  next(action);
  if (action.type === SUBMIT_SCAN_FORM_SUCCESS) {
    console.log(action.payload);
    dispatch(setFormScanLoading({ isLoading: false }));
    toast.success('Success!');
  }
};
const processSubmitFormError = ({ dispatch }) => (next) => async (action) => {
  next(action);
  if (action.type === SUBMIT_SCAN_FORM_ERROR) {
    dispatch(setFormScanLoading({ isLoading: false }));
    const { description, code } = action.payload;
    if (code === '3') {
      toast.warning(description);
    } else {
      toast.error('Ups, something wrong happen');
    }
  }
};

export const scanFormMdl = [
  fetchCompanyName,
  fetchSelectedCompanyInfoById,
  fetchSelectedCompanyInfo,
  formatCompanyFormInfo,
  setUrlParameters,
  submitForm,
  processSubmitFormResponse,
  processSubmitFormError,
];
