import axios from 'axios';
import { toast } from 'react-toastify';

import {
  FETCH_BUSINESS_RESULTS,
  FETCH_BUSINESS_RESULTS_ERROR,
  PARSE_BUSINESS_RESULTS,
} from '../types/businessResults';
import { setBusinessResults } from '../actions/businessResults';
import { setBusinessResultsLoading } from '../actions/ui';

import {
  getOutReachScore,
  getAverageScore,
  getPresenceScore,
} from '../../utils/getScores';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchBusinessResults = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_BUSINESS_RESULTS) {
    dispatch(setBusinessResultsLoading({ isLoading: true }));
    axios
      .get(`${backendUrl}/companies/details/${action.payload}`)
      .then((res) => {
        dispatch({
          type: PARSE_BUSINESS_RESULTS,
          payload: res.data.result,
        });
      })
      .catch((error) => {
        let messages = null;
        if (error.response) {
          messages = error.response.data.messages.description;
        }
        toast.error(`${messages || error}`);
        dispatch({
          type: FETCH_BUSINESS_RESULTS_ERROR,
          payload: messages || error,
        });
      });
  }
};

export const parseBusinessResults = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === PARSE_BUSINESS_RESULTS) {
    const results = action.payload;
    const outReachOverallScore = getOutReachScore({
      facebookPage: results?.silktideJson?.facebook.page,
      twitterLink: results?.silktideJson?.twitter.link,
      hasInstagram: results?.silktideJson?.instagram.hasInstagram,
    });
    const reputationOverallScore = results?.reputationScore?.overallScore;
    const presenceOverallScore = getPresenceScore({
      presence: results?.silktideJson?.overallScore,
    });
    const averageScore = getAverageScore({
      reputationScore: reputationOverallScore,
      presenceScore: presenceOverallScore,
      outReachScore: outReachOverallScore,
    });
    const listingAnalysisItems = results.yextJson.raw.map(
      ({
        siteId,
        name,
        address,
        phone,
        status,
        match_name: matchName,
        match_address: matchAddress,
        match_phone: matchPhone,
        review_count: reviewCount,
      }) => ({
        id: siteId,
        site: siteId,
        businessName: name,
        address,
        phone,
        reviews: reviewCount > 0,
        status,
        matchName,
        matchAddress,
        matchPhone,
      })
    );
    const facebookImage = results?.silktideJson?.facebook?.image || null;
    const twitterImage = results?.silktideJson?.twitter?.image || null;
    const businessImage = facebookImage || twitterImage || '';
    dispatch(
      setBusinessResults({
        businessresults: {
          outReachOverallScore,
          reputationOverallScore,
          presenceOverallScore,
          averageScore,
          businessImage,
          listingAnalysisItems,
          ...results,
        },
      })
    );
    dispatch(setBusinessResultsLoading({ isLoading: false }));
  }
};

export const businessResultsMdl = [fetchBusinessResults, parseBusinessResults];
