import { combineReducers } from 'redux';

// import reducers here
import { oAuth } from './oAuth';
import { userDB } from './userDB';
import { scanForm } from './scanForm';
import { ui } from './ui';
import { businessResults } from './businessResults';
import { translations } from './translations';

const RootReducer = combineReducers({
  oauth: oAuth,
  user: userDB,
  scanForm,
  ui,
  businessResults,
  translations,
});

export default RootReducer;
